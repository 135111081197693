var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(isNaN(_vm.career.id) && _vm.show_title)?_c('h4',[_vm._v(" Crear "+_vm._s(_vm.$getVisibleNames("mesh.career", false, "Programa"))+" ")]):(_vm.show_title)?_c('h4',[_vm._v(" Editar "+_vm._s(_vm.$getVisibleNames("mesh.career", false, "Programa"))+" ")]):_vm._e(),_c('div',[_c('b-form-group',{attrs:{"label":"Nombre","label-for":"name-input","label-cols":"0","label-cols-sm":"3"}},[_c('b-form-input',{attrs:{"id":"name-input","name":"name-input","state":_vm.validateState('name'),"aria-describedby":"input-name-live-feedback"},model:{value:(_vm.$v.career.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.career.name, "$model", $$v)},expression:"$v.career.name.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-name-live-feedback"}},[(_vm.career.name.length < 3)?_c('div',[_vm._v(" Este campo es obligatorio y debe tener al menos 3 caracteres. ")]):_vm._e()])],1),_c('b-form-group',{attrs:{"label":`${_vm.$getVisibleNames(
        'mesh.egressprofiletype',
        false,
        'Tipo de Programa'
      )}`,"label-for":"type-select","label-cols":"0","label-cols-sm":"3"}},[_c('b-form-select',{attrs:{"id":"type-select","name":"type-select","options":_vm.profileTypes,"text-field":"name","value-field":"id","state":_vm.validateState('egress_profile_type'),"aria-describedby":"select-type-live-feedback"},model:{value:(_vm.$v.career.egress_profile_type.$model),callback:function ($$v) {_vm.$set(_vm.$v.career.egress_profile_type, "$model", $$v)},expression:"$v.career.egress_profile_type.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"select-type-live-feedback"}},[_vm._v("Este campo es obligatorio.")])],1),_c('b-form-group',{attrs:{"label":_vm.$getVisibleNames('mesh.faculty', false, 'Facultad'),"label-for":"faculty-input","label-cols":"0","label-cols-sm":"3"}},[_c('b-form-select',{attrs:{"id":"faculty-input","name":"faculty-input","options":_vm.filterFacultiesTransversal,"text-field":"name","value-field":"id","state":_vm.validateState('faculty'),"aria-describedby":"input-faculty-live-feedback"},model:{value:(_vm.$v.career.faculty.$model),callback:function ($$v) {_vm.$set(_vm.$v.career.faculty, "$model", $$v)},expression:"$v.career.faculty.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-faculty-live-feedback"}},[_vm._v("Este campo es obligatorio.")])],1),(_vm.grandeGroupsList.length > 1)?_c('b-form-group',{attrs:{"label":_vm.$getVisibleNames(
          'evaluations2.graderangegroup',
          false,
          'Grupo de Rangos de Notas'
        ),"label-for":"chosen_grade_range_group-input","label-cols":"0","label-cols-sm":"3"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-block w-100"},[_c('b-form-select',{attrs:{"id":"chosen_grade_range_group-input","name":"chosen_grade_range_group-input","options":_vm.grandeGroupsList,"text-field":"name","value-field":"id","state":_vm.validateState('chosen_grade_range_group'),"aria-describedby":"input-chosen_grade_range_group-live-feedback"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"selected":""},domProps:{"value":null}},[_vm._v(" -- Seleccione un "+_vm._s(_vm.$getVisibleNames( "evaluations2.graderangegroup", false, "Grupo de Rangos de Notas" ))+" -- ")])]},proxy:true}],null,false,360863782),model:{value:(_vm.$v.career.chosen_grade_range_group.$model),callback:function ($$v) {_vm.$set(_vm.$v.career.chosen_grade_range_group, "$model", $$v)},expression:"$v.career.chosen_grade_range_group.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-chosen_grade_range_group-live-feedback"}},[_vm._v("Este campo es obligatorio.")])],1),_c('div',[(_vm.career.chosen_grade_range_group != null)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
              `Previsualizar ${_vm.$getVisibleNames(
                'evaluations2.graderange',
                true,
                'Rangos de Notas'
              )}`
            ),expression:"\n              `Previsualizar ${$getVisibleNames(\n                'evaluations2.graderange',\n                true,\n                'Rangos de Notas'\n              )}`\n            ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"ml-1 p-0",attrs:{"size":"sm","variant":"none"},on:{"click":function($event){return _vm.$bvModal.show(`modal-view-evaluations2.graderange-${_vm.career.id}`)}}},[_c('b-icon',{staticClass:"btn-actions",attrs:{"icon":"eye","scale":"1"}})],1):_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
              `Seleccione un ${_vm.$getVisibleNames(
                'evaluations2.graderangegroup',
                false,
                'Grupo de Rangos de Notas'
              )} para poder previsualizar.`
            ),expression:"\n              `Seleccione un ${$getVisibleNames(\n                'evaluations2.graderangegroup',\n                false,\n                'Grupo de Rangos de Notas'\n              )} para poder previsualizar.`\n            ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}]},[_c('b-button',{staticClass:"ml-1 p-0",attrs:{"size":"sm","variant":"none","disabled":""}},[_c('b-icon',{staticClass:"btn-actions",attrs:{"icon":"eye","scale":"1"}})],1)],1)],1)])]):_vm._e(),(_vm.achievementGroupsList.length > 1)?_c('b-form-group',{attrs:{"label":_vm.$getVisibleNames(
          'assessment2.achievementrangegroup',
          false,
          'Grupo de Rangos de Logro'
        ),"label-for":"chosen_achievement_range_group-input","label-cols":"0","label-cols-sm":"3"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-block w-100"},[_c('b-form-select',{attrs:{"id":"chosen_achievement_range_group-input","name":"chosen_achievement_range_group-input","options":_vm.achievementGroupsList,"text-field":"name","value-field":"id","state":_vm.validateState('chosen_achievement_range_group'),"aria-describedby":"input-chosen_achievement_range_group-live-feedback"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"selected":""},domProps:{"value":null}},[_vm._v(" -- Seleccione un "+_vm._s(_vm.$getVisibleNames( "assessment2.achievementrangegroup", false, "Grupo de Rangos de Logro" ))+" -- ")])]},proxy:true}],null,false,590356441),model:{value:(_vm.$v.career.chosen_achievement_range_group.$model),callback:function ($$v) {_vm.$set(_vm.$v.career.chosen_achievement_range_group, "$model", $$v)},expression:"$v.career.chosen_achievement_range_group.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-chosen_achievement_range_group-live-feedback"}},[_vm._v("Este campo es obligatorio.")])],1),_c('div',[(_vm.career.chosen_achievement_range_group != null)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
              `Previsualizar ${_vm.$getVisibleNames(
                'assessment2.achievementrange',
                true,
                'Rangos de Logro'
              )}`
            ),expression:"\n              `Previsualizar ${$getVisibleNames(\n                'assessment2.achievementrange',\n                true,\n                'Rangos de Logro'\n              )}`\n            ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"ml-1 p-0",attrs:{"size":"sm","variant":"none"},on:{"click":function($event){return _vm.$bvModal.show(
                `modal-view-assessment2.achievementrange-${_vm.career.id}`
              )}}},[_c('b-icon',{staticClass:"btn-actions",attrs:{"icon":"eye","scale":"1"}})],1):_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
              `Seleccione un ${_vm.$getVisibleNames(
                'assessment2.achievementrangegroup',
                false,
                'Grupo de Rangos de Logro'
              )} para poder previsualizar.`
            ),expression:"\n              `Seleccione un ${$getVisibleNames(\n                'assessment2.achievementrangegroup',\n                false,\n                'Grupo de Rangos de Logro'\n              )} para poder previsualizar.`\n            ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}]},[_c('b-button',{staticClass:"ml-1 p-0",attrs:{"size":"sm","variant":"none","disabled":""}},[_c('b-icon',{staticClass:"btn-actions",attrs:{"icon":"eye","scale":"1"}})],1)],1)],1)])]):_vm._e(),(
        (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
        _vm.$debug_change_duoc
      )?_c('b-form-group',{staticClass:"label my-2",attrs:{"label-cols":"0","label-cols-sm":"3","label":"Carrera Genérica","label-for":"input-generic_career"}},[_c('v-select',{attrs:{"id":"input-generic_career","options":_vm.genericCareers,"reduce":(genericCareers) => genericCareers.id,"placeholder":`${
          _vm.genericCareers.length > 0
            ? String(
                'Seleccione una ' +
                  _vm.$getVisibleNames(
                    'mesh.genericcareer',
                    false,
                    'Carrera Génerica'
                  ) +
                  '.'
              )
            : String(
                'No cuenta con ' +
                  _vm.$getVisibleNames(
                    'mesh.genericcareer',
                    true,
                    'Carreras Génericas'
                  ) +
                  ' creadas.'
              )
        }`,"dropdown-should-open":_vm.dropdownShouldOpenGenericCareer,"state":_vm.validateState('generic_career'),"label":"name","track-by":"id","size":"sm"},scopedSlots:_vm._u([{key:"no-options",fn:function({ search, searching }){return [(searching)?[_vm._v(" No se encontraron resultados para: \""),_c('em',[_vm._v(_vm._s(search))]),_vm._v("\" ")]:_vm._e()]}}],null,false,4042988302),model:{value:(_vm.$v.career.generic_career.$model),callback:function ($$v) {_vm.$set(_vm.$v.career.generic_career, "$model", $$v)},expression:"$v.career.generic_career.$model"}})],1):_vm._e(),_c('div',{staticClass:"row"},[(_vm.show_delete_button && !isNaN(_vm.career.id))?_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"danger"},on:{"click":_vm.deleteCareer}},[_vm._v("Eliminar")])],1):_vm._e(),(_vm.show_save_button)?_c('div',{staticClass:"col",staticStyle:{"text-align":"right"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":_vm.save}},[_vm._v("Guardar")])],1):_vm._e()]),(_vm.career.chosen_grade_range_group != null)?_c('b-modal',{attrs:{"id":`modal-view-evaluations2.graderange-${_vm.career.id}`,"title":`${
        (
          _vm.grade_ranges_groups.find(
            (x) => x.id == _vm.career.chosen_grade_range_group
          ) || {}
        ).name || ''
      }`,"size":"lg","hide-footer":""}},[_c('GradeTableRanges',{attrs:{"grade_ranges":_vm.grade_ranges,"grade_ranges_groups":_vm.grade_ranges_groups,"grade_range_group_id":_vm.career.chosen_grade_range_group}})],1):_vm._e(),(_vm.career.chosen_achievement_range_group != null)?_c('b-modal',{attrs:{"id":`modal-view-assessment2.achievementrange-${_vm.career.id}`,"title":`${
        (
          _vm.achievement_ranges_groups.find(
            (x) => x.id == _vm.career.chosen_achievement_range_group
          ) || {}
        ).name || ''
      }`,"size":"lg","hide-footer":""}},[_c('AchievementTableRanges',{attrs:{"achievement_ranges":_vm.achievement_ranges,"achievement_ranges_groups":_vm.achievement_ranges_groups,"achievement_range_group_id":_vm.career.chosen_achievement_range_group}})],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }